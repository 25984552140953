/**
 * Key Number Animation
 * @param {Element} $el
 * @param {number} tick
 * @returns {{init(): void, start: number, end: number, target: null, animation(): void}}
 */
window.keyNumber = ($el, tick) => {
    return {
        start: 0,
        end: 0,
        target: null,
        init() {
            this.target = $el.children[0];
            this.end = parseInt(this.target.innerText);
            this.target.innerText = this.start;
            const observer = new IntersectionObserver((e) => {
                if (e[0].isIntersecting) {
                    this.animation();
                    observer.unobserve(e[0].target);
                }
            });
            observer.observe($el);
        },
        animation() {
            const { start, end, target } = this;
            const interval = tick / 100;
            let step = end / (tick / interval);
            step = step < 1 ? 1 : step;

            let current = start;

            const handle = setInterval(function () {
                if (current < end) {
                    current = Math.floor(current + step);
                } else {
                    current = end;
                    clearInterval(handle);
                }
                target.innerText = current;
            }, interval);
        },
    };
};
