<script setup>
import {ref} from 'vue';
import ResponsiveMenuItem from '@/vue/ResponsiveMenuItem.vue';
import MenuButton from '@/vue/MenuButton.vue';

const openedItemId = ref(null);
const isMenuOpened = ref(false);

const props = defineProps({
  ariaLabel: {type: String, default: null},
  nav: {type: Array, required: true},
});

function toggleItem(itemId, isOpen) {
  openedItemId.value = isOpen ? itemId : null;
}
</script>

<template>
  <nav class="responsive-menu flex flex-col xl:flex-row bg-white shadow-md" :class="{ 'responsive-menu--is-open': isMenuOpened }">
    <div class="responsive-menu__top-bar flex justify-between xl:self-center items-center h-20 px-5 py-4 bg-white">
      <slot name="logo" />
      <menu-button class=" responsive-menu__button" type="collapse" v-model="isMenuOpened" />
    </div>


    <div class="responsive-menu__menu-container xl:ml-auto">
      <div class="flex flex-col h-full justify-between xl:items-end mx-2.5 xl:ml-0">
        <slot name="subsidiary-nav" />

        <ol class="responsive-menu__menu">
          <responsive-menu-item v-for="(item, i) in nav"
                                :key="i"
                                :item="item"
                                :is-open="openedItemId === i"
                                class="responsive-menu__item"
                                @toggle="(isOpen) => toggleItem(i, isOpen)"
          />

          <slot name="cta" />
        </ol>
      </div>
    </div>

    <div class="xld:hidden">
      <slot name="picture" />
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.responsive-menu {
  @apply relative;

  &__menu-container {
    height: 0;
    overflow: hidden;
    transition: height 200ms ease-out;
  }

  &__menu {
    @apply flex flex-col xl:flex-row xl:items-end w-full h-full uppercase gap-x-4;
    overflow-y: auto;
    overflow-x: visible;
  }

  &__item {
    @apply border-b last:border-0 border-theme xl:border-black xl:font-black;
  }

  @screen xld {
    &--is-open &__menu-container {
      height: calc(100vh - 80px);
      padding-bottom: 100px;

      @supports (height: 100dvh) {
        height: calc(100dvh - 80px);
        padding-bottom: 0;
      }
    }
  }

  @screen xl {
    & {
      height: 130px;
    }

    &__menu-container {
      @apply h-auto overflow-visible;
    }

    &__menu {
      @apply flex items-center overflow-visible;
      flex-basis: content;
    }

    &__button {
      @apply hidden;
    }

    &__item {
      @apply border-0;
    }
  }
}
</style>
