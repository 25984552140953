<script setup>
  import { onMounted, ref } from 'vue';

  const props = defineProps({
    item: { type: Object, required: true },
    isOpen: { type: Boolean, default: false }
  });

  const emit = defineEmits(['toggle']);

  // Used to prevent double toggling when clicking an item that doesn't have focus yet
  let areClickEventsPrevented = false;
  function preventClickEvents() {
    areClickEventsPrevented = true;
    setTimeout(() => {
      areClickEventsPrevented = false;
    }, 100);
  }

  function toggle(event) {
    if (
      event.name !== 'click' &&
      event.currentTarget.contains(event.relatedTarget)) {
      return;
    }

    preventClickEvents();
    emit('toggle', !props.isOpen);
  }

  function onClick(event) {
    if (areClickEventsPrevented) {
      return;
    }

    emit('toggle', false);
    event.currentTarget.blur();
  }


  // Handle dropdown background
  const background = ref(null);
  const root = ref(null);
  const dropdown = ref(null);
  const height = ref(0);
  onMounted(() => {
    if (!props.item.children) {
      return;
    }

    setBackgroundTargetHeight();
  });

  function onMouseOut(event) {
    if (isTouchDevice() || !window.matchMedia('(min-width: 1170px)').matches) {
      return;
    }

    emit('toggle', false);
    event.target.blur();
  }

  function isTouchDevice() {
    return window.matchMedia('(hover: none)').matches || window.matchMedia('(pointer: coarse)').matches;
  }

  function setBackgroundTargetHeight () {
    // Disable transition
    dropdown.value.style.transition = 'none';

    // Actual measurement
    root.value.classList.add('menu-item--force-dropdown');
    height.value = dropdown.value.offsetHeight;
    root.value.classList.remove('menu-item--force-dropdown');

    // Force a reflow of the element
    dropdown.value.offsetHeight;

    // Re-enable the transition
    dropdown.value.style.transition = null;
  }
</script>

<template>
  <li ref="root"
      class="menu-item"
      :class="{
        'menu-item--has-children': item.children,
        'menu-item--is-open': isOpen,
        'menu-item--is-current': item.isCurrent,
      }"
      :aria-expanded="isOpen"
      @focusout="toggle"
      @mouseout="onMouseOut"
  >
    <a v-if="item.children"
       @click="onClick"
       @keydown.space.prevent.stop="toggle"
       @focusin="toggle"
       v-text="item.text"
       class="menu-item__text"
       role="button"
       href="#"
       tabindex="0"
    />
    <a v-else :href="item.href" :target="item.target" class="menu-item__text" v-text="item.text" />

    <div ref="dropdown" v-if="item.children" class="menu-item__children-wrapper">
      <div class="menu-item__children-background hidden xl:block" :style="{ '--target-height': `${height}px` }" />
      <ol class="menu-item__children">
        <responsive-menu-item v-for="(subItem, i) in item.children" :key="i" :item="subItem" />
      </ol>
    </div>
  </li>
</template>

<style lang="scss">
  .menu-item {
    --dropdown-transition-duration: 250ms;

    @apply flex flex-col font-black font-bold;
    @screen xl {
      @apply after:rounded-tl after:rounded-tr after:transition-colors after:duration-200 after:h-2 hover:after:bg-theme focus:after:bg-theme;
    }

    &--has-children > &__text {
      @apply flex justify-between items-center;

      &::after {
        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMwYzk4ZWEiIHN0cm9rZT0iIzBjOThlYSIgdmlld0JveD0iMCAwIDIwIDIwIj4KICA8cGF0aCBkPSJNMTYuMDcgOC41N2MtLjQuNDItNC43IDQuNS00LjcgNC41YTEuMSAxLjEgMCAwIDEtMS41NyAwcy00LjI5LTQuMDgtNC43LTQuNWExLjIgMS4yIDAgMCAxIDAtMS42MWMuNDQtLjQ1IDEuMDUtLjQ4IDEuNTggMGwzLjkxIDMuNzUgMy45LTMuNzZjLjU0LS40OCAxLjE1LS40NSAxLjU4IDAgLjQ0LjQ0LjQxIDEuMiAwIDEuNjF6Ii8+Cjwvc3ZnPgo=");
        width: 16px;
        height: 16px;
        transition: transform 150ms ease-in-out;
      }
    }

    &--is-current {
      @apply xl:after:bg-theme;
    }

    &__children-wrapper {
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows var(--dropdown-transition-duration) ease-out;
    }

    &__children {
      @apply overflow-hidden -mt-3 xl:mt-0 pb-3 xl:pb-0;
    }

    &__text {
      @apply relative duration-200 px-3 py-5 xl:py-7 text-14 text-theme focus-visible:outline-none;

      &:focus-visible {
        outline: none;

        &::before {
          content: '';
          @apply absolute inset-[3px] ring ring-theme;
        }
      }
    }

    &__children &__text {
      @apply font-normal text-black xl:text-white py-2 normal-case;
    }

    @screen xld {
      &--is-open.menu-item--has-children > &__text::after {
        transform: rotate(180deg);
      }

      &--is-open &__children-wrapper {
        grid-template-rows: 1fr;
      }
    }

    @screen xl {
      & {
        @apply h-full justify-center;
      }

      &__text {
        @apply text-current;
      }

      &--has-children > &__text::after {
        content: none;
      }

      &__children-wrapper {
        @apply grid justify-center absolute top-full opacity-0;
        transition-property: all;
      }

      &--has-children:hover > &__children-wrapper,
      &--has-children:focus-within > &__children-wrapper,
      &--force-dropdown > &__children-wrapper {
        @apply opacity-100;
        grid-template-rows: 1fr;
      }

      &__children {
        @apply grid px-0 py-0;
        grid-template-columns: minmax(0, max-content);
      }

      &__children & {
        &:first-child {
          @apply pt-6;
        }
        &:last-child {
          @apply pb-6;
        }
      }

      &__children &__text {
        @apply flex w-fit text-white py-1;

        &:hover,
        &:focus {
          color: var(--color-theme-title);
        }
      }

      &__children-background {
        @apply fixed inset-x-0 bg-theme transition shadow-xl;
        height: 0;
        transition: height var(--dropdown-transition-duration) ease-out;

        &::after {
          content: '';
          @apply block w-full h-full opacity-20;
          background-image: var(--bg-image-url);
          background-repeat: no-repeat;
          background-size: 200px;
          background-position: 10% 2rem;
        }
      }

      &--has-children:hover &__children-background,
      &--has-children:focus-within &__children-background,
      &--force-dropdown &__children-background {
        height: var(--target-height);
      }
    }
  }
</style>
