/**
 * Create a map with markers using mapbox
 */
window.createMap = () => {
    return {
        container: null,
        root: null,
        config: null,
        loaded: false,
        markers: null,
        map: null,
        async init(root) {
            if (root) {
                this.container = this.$refs.container;
                this.root = root;
                this.config = this.makeConfig();
                await this.renderMap();
                this.renderMarkers();
            }
        },
        makeConfig() {
            return {
                container: this.container,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [2, 46],
                zoom: 4,
                accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
            };
        },
        renderMap() {
            return new Promise((resolve) => {
                this.map = new mapboxgl.Map(this.config);
                this.map.on('idle', () => {
                    if (!this.loaded) {
                        this.loaded = true;
                        this.map.setLayoutProperty(
                            'country-label',
                            'text-field',
                            ['get', `name_fr`]
                        );
                        return resolve(true);
                    }
                });
            });
        },
        renderMarkers() {
            const events = JSON.parse(this.root.getAttribute('data-events'));
            events.map((event) =>
                new mapboxgl.Marker()
                    .setLngLat(event.coordinates)
                    .setPopup(
                        new mapboxgl.Popup().setHTML(`
                    <h3 class="heading-3 text-theme-dark mb-4" style="line-height: 1.2em">${
                        event.title
                    }</h3>
                    <p class="mb-4">${event.description}</p>
                    ${
                        event.url
                            ? `<a href="${event.url}" class="button button--primary" style="font-size: .75rem !important;">
                                Lien vers l’événement
                           </a>`
                            : ''
                    }
                `)
                    )
                    .addTo(this.map)
            );
        },
    };
};
