import Alpine from 'alpinejs';
import 'htmx.org/dist/htmx';
import './js/components/key-number';
import './js/vendors/orejime';
import './js/vendors/mapbox';
import './style.css';
import {createApp} from 'vue';
import ResponsiveMenu from "@/vue/ResponsiveMenu.vue";

// App main
const header = async () => {
    // Create our vue instance
    const app = createApp({
        name: 'Header',
        components: {
            ResponsiveMenu,
        },
    });

    // Mount the app
    return app.mount('#header');
};

// Execute async function
header().then(() => {
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept();
}

window.Alpine = Alpine;
Alpine.start();
