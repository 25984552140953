import Orejime from 'orejime/dist/orejime';

// Init
let apps = [];
let translations = {
    accept: 'J’accepte',
    decline: 'Je refuse',
};
let translationsPurposes = {};

// Necessary config
translations['necessary'] = {
    description:
        'Assure la sécurité et les fonctionnalités fondamentales du site',
};

translationsPurposes['necessary'] =
    'Cookies nécessaires au fonctionnement du site';

apps.push({
    name: 'necessary',
    title: 'Nécessaire',
    purposes: ['necessary'],
    required: true,
    cookies: ['CraftSessionId', 'CRAFT_CSRF_TOKEN', 'orejime'],
});

// Analytics config
translationsPurposes['analytics'] = 'Statistiques de visite';

if (typeof gtmId !== 'undefined') {
    translations['google-analytics'] = {
        description: 'Mesure d\'audience',
    };

    apps.push({
        name: 'google-analytics',
        title: 'Google Analytics & Google Tag Manager',
        purposes: ['analytics'],
        required: false,
        cookies: [
            '_ga',
            '_gat',
            '_gid',
            '__utma',
            '__utmb',
            '__utmc',
            '__utmt',
            '__utmz',
            '_gcl_au',
            /_ga_.+/,
            /_gat_.+/,
            '_fbp',
        ],
    });
}

// Social network and video config
translations['social'] = {
    description:
        'Affichage des flux et contenus des réseaux sociaux (Facebook, Twitter, Youtube et SoundCloud)',
};

translationsPurposes['social'] = 'Réseaux sociaux et vidéos';

apps.push({
    name: 'social',
    title: 'Réseaux sociaux',
    purposes: ['social'],
    required: false,
    cookies: [
        '__Secure-3PSIDCC',
        'SIDCC',
        '__Secure-3PAPISID',
        'HSID',
        '__Secure-3PSID',
        'SID',
        'SAPISID',
        'APISID',
        'SSID',
        'NID',
        'OTZ',
        'ANID',
        'SEARCH_SAMESITE',
        'CONSENT',
        '1P_JAR',
        'DSID',
        'IDE',
        '_ga',
        'external_referer',
        'gt',
        '_twitter_sess',
        'ct0',
        '_gid',
        'guest_id',
        'personalization_id',
        'sc_anonymous_id',
        '_pxvid',
        '_px3',
        'sclocale',
        '_fbp',
        'fr',
        'xs',
        'presence',
        'wd',
        'spin',
        'c_user',
        'sb',
        'datr',
    ],
});

translations['purposes'] = translationsPurposes;

window.orejime = Orejime.init({
    privacyPolicy:
        typeof privacyPage !== 'undefined' ? privacyPage : '/mentions-legales',
    debug: false,
    elementID: 'orejime',
    appElement: '#main',
    translations: {
        fr: translations,
    },
    apps: [...apps],
});
