<script setup>
const isOpen = defineModel({type: Boolean, required: true});
</script>

<template>
  <button type="button"
          class="bg-theme rounded"
          :class="{ open: isOpen }"
          @click="isOpen = !isOpen"
          :aria-expanded="isOpen"
  >
    <div class="relative w-full h-full">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </div>
  </button>
</template>

<style scoped lang="scss">
button {
  --size: 2.5rem;
  --color: white;
  --color-open: var(--color-theme-dark-alt);
  --background: var(--color-theme);
  --background-open: var(--color-theme-light-alt);
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  padding: 0.781rem 0.625rem;

  div {
    --size: 1.25rem;
    width: var(--size);
    height: calc(var(--size) * 0.75);
  }
}

span {
  display: block;
  position: absolute;
  height: calc(var(--size) * 0.15);
  width: 100%;
  background: var(--color);
  border-radius: 999rem;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.open {
  background: var(--background-open);
}

.open span {
  background: var(--color-open);
}

span:nth-child(1) {
  top: 0;
  transform-origin: left center;
}

span:nth-child(2) {
  top: calc(var(--size) * 0.3);
  transform-origin: left center;
}

span:nth-child(3) {
  top: calc(var(--size) * 0.60);
  transform-origin: left center;
}

.open span:nth-child(1) {
  transform: rotate(45deg);
  top: calc(var(--size) * -0.05);
  left: calc(var(--size) * 0.133333);
}

.open span:nth-child(2) {
  width: 0;
  opacity: 0;
}

.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: calc(var(--size) * 0.65);
  left: calc(var(--size) * 0.133333);
}
</style>
